<template>
  <svg
    width="12px"
    height="10px"
    viewBox="0 0 12 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
    <title>2919C0C8-3DED-4B14-A4E1-646E9EEAE7D5</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Spec"
        transform="translate(-671.000000, -616.000000)"
        :fill="fillColor"
        fill-rule="nonzero"
      >
        <g id="back" transform="translate(671.000000, 616.242930)">
          <g id="Path">
            <path
              d="M11.0423268,3.95750122 L11.0605902,3.96149634 L3.23148293,3.96149634 L5.69266829,1.49488902 C5.8131878,1.37446463 5.87929756,1.21133049 5.87929756,1.04011098 C5.87929756,0.868891463 5.8131878,0.70689878 5.69266829,0.586189024 L5.30970732,0.203037805 C5.18928293,0.0826134146 5.0288122,0.0160280488 4.8576878,0.0160280488 C4.68646829,0.0160280488 4.52590244,0.0821378049 4.40547805,0.202562195 L0.186534146,4.42112561 C0.0656341463,4.54202561 -0.000475609756,4.70306707 -2.54844698e-06,4.87438171 C-0.000475609756,5.04664756 0.0656341463,5.20778415 0.186534146,5.3284939 L4.40547805,9.5474378 C4.52590244,9.66776707 4.68637317,9.73397195 4.8576878,9.73397195 C5.0288122,9.73397195 5.18928293,9.66767195 5.30970732,9.5474378 L5.69266829,9.16428659 C5.8131878,9.04405244 5.87929756,8.88348659 5.87929756,8.71226707 C5.87929756,8.54114268 5.8131878,8.38904268 5.69266829,8.26871341 L3.20370732,5.78831341 L11.051078,5.78831341 C11.4036951,5.78831341 11.7,5.48439878 11.7,5.13197195 L11.7,4.5900622 C11.7,4.23763537 11.3949439,3.95750122 11.0423268,3.95750122 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    showHoveredColor: {
      type: Boolean
    }
  },
  data() {
    return {
      fillColor: "#ffffff",
      normalFill: "#ffffff",
      hoveredFill: "#E81C25"
    };
  },
  watch: {
    showHoveredColor(newVal) {
      this.fillColor = newVal ? this.hoveredFill : this.normalFill;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>